




















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SystemParameterService from '@/services/SystemParameterService';

@Component({
  components: {}
})
export default class Dashboard extends Vue {
  // Data properties
  public dashboardLink = '';
  public isLoaded = false;

  // Lifecycle hooks
  public async created(): Promise<void> {
    this.dashboardLink = await SystemParameterService.getSystemParameterString(
      'EmbeddedDashboardLink'
    );
  }

  public disableKeyForwarding(): void {
    try {
      const grafanaIframe = this.$refs.grafana as HTMLIFrameElement;
      grafanaIframe?.contentWindow?.addEventListener(
        'keydown',
        function (event) {
          const escapeKey = '27'; // 'Esc'
          if (event.key == escapeKey) {
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        },
        true
      );
    } catch {
      // this might happen if the iframe is cross origin where this is not possible - in this case keys will still work
    }
  }

  public onLoadingFinished(): void {
    this.disableKeyForwarding();
    this.isLoaded = true;
  }
}
